@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
/* 
*
*
Root Colours Configurations 
*
*
*/

:root {
  --primaryTheme: #07588f;
  --appTheme: #f7f7f8;
  --grey: rgba(0, 0, 0, 0.7);
  --dark: #000000;
  --light: #ffff;
  --tasksText: #332620;
  --profileMain: #f8fbff;

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-28: 28px;
  --font-size-30: 30px;
  --font-size-32: 32px;
}

::-webkit-scrollbar {
  display: true;
}

/* 
*
*
Common App Configuration 
*
*
*/

body {
  margin: 0;
  font-family: "Red Hat Text", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--appTheme) !important;
  letter-spacing: 0.02em;
}
/**
 following is the place to over write the client configurations. example: background color over write the variable
*/
#client-specific-container body {
  /* background-color: var(--primaryTheme); */
  /* Apply other client-specific configurations */
}

#client-specific-container .font-normal {
  /*font-size: 18px; */ /* Override font size for this client */
  /* Apply other client-specific font styles */
}

body::-webkit-scrollbar {
  display: none;
}

/* 
*
*
Basic App Components 
*
*
*/

.cursor-pointer {
  cursor: pointer;
}

.head-container {
  padding-top: 28px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 35px;
  z-index: 999999999999999999;
  background-color: #ffff;
}

.PageContainer {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 600px) {
  .PageContainer {
    padding: 10px 20px;
  }
}

.error-field {
  font-size: var(--font-size-12);
  color: red;
  font-weight: 600;
}

.divider {
  background-image: linear-gradient(
    to left,
    rgba(254, 254, 254, 0) 3%,
    var(--primaryTheme) 100%
  );
  height: 2px;
  width: 100%;
  opacity: 0.4;
}

.scrollable-container {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;

  overflow: auto;
}

.scrollable-container::-webkit-scrollbar {
  width: 2px;
  overflow: auto;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #afa9a9;
  border-radius: 10px;
}

.common-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
*
*
Application Typography  
*
*
*/

/* 
*
*
Black Fonts 
*
*
*/

/* 12px black fonts */

.font-black-12-normal {
  font-size: var(--font-size-12);
  color: var(--dark);
}

.font-black-12-bold {
  font-size: var(--font-size-12);
  color: var(--dark);
  font-weight: bold;
}

/* 14px black fonts */

.font-black-14-normal {
  font-size: var(--font-size-14);
  color: var(--dark);
}

.font-black-14-bold {
  font-size: var(--font-size-14);
  color: var(--dark);
  font-weight: bold;
}

/* 16px black fonts */

.font-black-16-normal {
  font-size: var(--font-size-16);
  color: var(--dark);
}

.font-black-16-bold {
  font-size: var(--font-size-16);
  color: var(--dark);
  font-weight: bold;
}

/* 18px black fonts */

.font-black-18-bold {
  font-size: var(--font-size-18);
  color: var(--dark);
  font-weight: bold;
}

/* 20px black fonts */

.font-black-20-bold {
  font-size: var(--font-size-20);
  color: var(--dark);
  font-weight: bold;
}

/* 24px black fonts */

.font-black-24-bold {
  font-size: var(--font-size-24);
  color: var(--dark);
  font-weight: bold;
}

/* 
*
*
Primary Fonts 
*
*
*/

/* 14px primary fonts */

.font-primary-14-bold {
  font-size: var(--font-size-14);
  color: var(--primaryTheme);
  font-weight: bold;
}

/* 16px primary fonts */

.font-primary-16-bold {
  font-size: var(--font-size-16);
  color: var(--primaryTheme);
  font-weight: bold;
}

/* 
*
*
Grey Fonts 
*
*
*/

/* 11px grey fonts */

.font-grey-11-normal {
  font-size: 11px;
  color: var(--grey);
}

/* var(--font-size-12) grey fonts */

.font-grey-12-normal {
  font-size: var(--font-size-12);
  color: var(--grey);
}

.font-grey-12-bold {
  font-size: var(--font-size-12);
  color: var(--grey);
  font-weight: bold;
}

/* 14px grey fonts */

.font-grey-14-bold {
  font-size: var(--font-size-14);
  color: var(--grey);
  font-weight: bold;
}

/* 18px grey fonts */

.font-grey-18-normal {
  font-size: var(--font-size-18);
  color: var(--grey);
  font-weight: 500;
}

/*
*
*
White Fonts 
*
*
*/

/* 16px white fonts */

.font-white-16-bold {
  font-size: var(--font-size-16);
  color: var(--light);
  font-weight: bold;
}

.font-black-32-bold {
  font-size: 32px;
  color: var(--dark);
  font-weight: 900;
}

.font-grey-14-normal {
  font-size: 14px;
  color: var(--grey);
}

.scrollBar {
  width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #3470ff #f5f5f5;
}

.scrollBar::-webkit-scrollbar {
  height: 8px;
}

.scrollBar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.7);
  border-radius: 8px;
}

.scrollBar::-webkit-scrollbar-thumb:hover {
  background-color: #1e467a;
}

/* 
*
*
Other Typographs
*
*
*/

.font-green-20-bold {
  font-size: var(--font-size-20);
  color: var(--secondaryGreen);
  font-weight: bold;
}

.font-brown-20-bold {
  font-size: var(--font-size-20);
  color: var(--BrownFont);
  font-weight: bold;
}

.font-green-12-bold {
  font-size: var(--font-size-12);
  color: var(--secondaryGreen);
  font-weight: bold;
}

/* 
*
*
Status Tags 
*
*
*/

.review-status-button {
  border: 2px solid #482b04;
  background-color: #fdedd8;
  color: #480448;
  font-size: var(--font-size-12);
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done-status-button {
  border: 2px solid #2d4804;
  background-color: #e8fdd8;
  color: #480448;
  font-size: var(--font-size-12);
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
*
*
Application Buttons 
*
*
*/

.primary-button {
  background-color: var(--primaryTheme);
  color: #fff;
  border: none;
}

.secondary-button {
  background-color: #fff;
  color: var(--primaryTheme);
  border: 1px solid var(--primaryTheme);
}

.primary-contained-button {
  background-color: var(--primaryTheme);
  border: 0;
  border-radius: 4px;
  color: #ffff;
}

/* 
*
*
DashBoard Files 
*
*
*/

.border-bottom-startups {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

/* 
*
*
Startup Files 
*
*
*/

.input-field {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.input-field:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.double-border {
  margin: 0;
  padding-bottom: 7px;
  position: relative;
  border-bottom: 1px solid #000;
}

.double-border::before {
  position: absolute;
  background: red;
  height: 3px;
  content: "";
  width: 98px;
  bottom: -2px;
  left: 0;
}

.floating-button {
  position: fixed;
  bottom: 6%;
  right: 2.5%;
}

/* 
*
*
Loaders 
*
*
*/

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.FormContainer {
  padding: 30px 220px 30px 220px;
}

.login-right {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  /* background-position: center !important; */
  border-radius: 0px 20px 20px 0px;
  height: 100vh;
}

.login-left {
  /* background: url(./Assets/EventImgLeft.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.login-fields {
  border: 1px solid var(--formBorderColor) !important;
  background-color: #fff !important;
  height: 50px;
  color: black;
  background-color: var(--selectBackground) !important;
  color: var(--grey2) !important;
  width: 100%;
  border: none !important;
  padding: 10px;
  border-radius: 4px;
}

.login-primary-contained-button {
  background: rgba(7, 88, 143, 1);
  color: #fff;
  border: transparent;
}

.resend-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: green;
}

/* Avatar and Username (Header->index.tsx) */
.logoutOutline {
  border: none !important;
}

.logoutOutline:focus {
  box-shadow: none !important;
}
